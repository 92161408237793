import React from 'react';
import './fonts.css';
import './App.css';
import TileGrid from './TileGrid';


function App() {
    return (
        <div className="App">
            <section className="upper-page content">
                <div className="overlay overlay-dark-30-video">
                    <video className="video-background" autoPlay loop muted>
                        <source src="/assets/enot.webm" type="video/webm" />
                    </video>
                </div>
                <div className="center-container">
                    <h1 className="title">enot.dev</h1>
                    <h2 className="subtitle">one man band</h2>
                </div>
            </section>
            <section className="downer-page">
                {/*<TileGrid />*/}
            </section>
        </div>
    );
}

export default App;